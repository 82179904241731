var render, staticRenderFns
import script from "./waybill-edit.vue?vue&type=script&lang=js&"
export * from "./waybill-edit.vue?vue&type=script&lang=js&"
import style0 from "./waybill-edit.vue?vue&type=style&index=0&id=1be0c741&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.7@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1be0c741",
  null
  
)

export default component.exports